.Content{
    .TableInfo Button {
        margin: 10px 10px 10px 0;
    }
}

:where(.css-dev-only-do-not-override-umnkyy).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select, :where(.css-dev-only-do-not-override-umnkyy).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
    width: 100%;
    height: 100%;
    margin-inline-end: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    border-radius: 8px;
    cursor: pointer;
    transition: border-color 0.3s
}