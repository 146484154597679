/* .Content{
    padding: 0 50px;
    background-color: white;
} */

.onboarding1 {}

:where(.css-dev-only-do-not-override-umnkyy).ant-btn-primary {
    color: #fff;
    background-color: rgba(24, 119, 242, 1);
    box-shadow: 0 2px 0 rgba(142, 255, 30, 0.08);
    /* font-family: Arial; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.ant-card-type-inner .ant-card-head-title{
    font-size: 16px;
}

.onboardingCommon{
    #charterCapital{
        height: 40px;
    }
    .ant-input-number-input-wrap{
        height: 100%;
    }
    .ant-input-number-input{
        height: 100%;
    }
}