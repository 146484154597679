@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');

body {
  margin: 0;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: '1.6em';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: '1.6em';
}

.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: rgb(245 245 245);
}


.ant-btn {
  font-size: 16px;
  line-height: 1.5;
  height: 32px;
  padding: 3px 24px;
  border-radius: 6px;
}