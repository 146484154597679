.userInfo .ant-upload-wrapper{
    display: flex;
    justify-content: center;
}

.userInfo .ant-upload-select{
    width: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userInfo{
    .ant-image {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    .ant-card-type-inner .ant-card-body {
        padding: 16px 24px;
        padding-top: 0px;
    }

    /* .ant-badge.ant-badge-status .ant-badge-status-text {
        margin-inline-start: 8px;
        color: #52c41a;
        font-size: 16px;
    } */
}