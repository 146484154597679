.onboarding3 {
    .Type {
        margin: 13px 0 0 120px;
    }

    .IMAGE-SECTION {
        display: flex;
    }

    .IMAGE-SECTION .DIV-IMAGE {
        border: 2px dotted #ddd;
        padding: 10px;
        border-radius: 5px;
        margin: 20px 20px 20px 0;
    }

    .IMAGE-SECTION .DIV-IMAGE img {
        max-width: 100%;
        max-height: 250px;
        display: block;
        margin: 0 auto;
        width: 100%;
    }

    .IMAGE-SECTION .DIV-IMAGE Button {
        width: 350px;
        background-color: rgb(219, 237, 249);
        color: rgb(47, 128, 237);
    }
}

:where(.css-dev-only-do-not-override-umnkyy).ant-btn-primary {
    color: #fff;
    background-color: rgba(24, 119, 242, 1);
    box-shadow: 0 2px 0 rgba(142, 255, 30, 0.08);
    /* font-family: Arial; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

:where(.css-dev-only-do-not-override-umnkyy).ant-space-gap-col-small {
    column-gap: 60px;
}

.empowerChiefAccount{
    .ant-upload-select{
        width: 100%;
    }
}