:where(.css-dev-only-do-not-override-umnkyy).ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: white;
    border-color: white;
}

:where(.css-dev-only-do-not-override-umnkyy).ant-steps.ant-steps-with-progress .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon {
    color: #1877F2;
    /* font-family: Arial; */
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 157.143% */
}

:where(.css-dev-only-do-not-override-umnkyy).ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #1877F2;
}

:where(.css-dev-only-do-not-override-umnkyy).ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: rgba(24, 119, 242, 1);
    border-color: rgba(24, 119, 242, 1);
}

:where(.css-dev-only-do-not-override-umnkyy).ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #FFF;
    /* font-family: Arial; */
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 157.143% */
}

:where(.css-dev-only-do-not-override-umnkyy).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #1877F2;
}

:where(.css-dev-only-do-not-override-umnkyy).ant-steps .ant-steps-item-title {
    position: relative;
    display: inline-block;
    padding-inline-end: 16px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
}

.ant-menu-light .ant-menu-item {
    color: black
}


.stepOnboarding{
    /* .ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
        margin-inline-start: 80px;
        padding: 4px 24px;
    }

    .ant-steps.ant-steps-label-vertical .ant-steps-item-icon {
        display: inline-block;
        margin-inline-start: 20px;
        margin-left: 4em;
    } */

    .ant-steps .ant-steps-item-title {
        position: relative;
        display: inline-block;
        color: rgba(0, 0, 0, 0.88);
        font-size: 18px;
        line-height: 24px;
        font-weight: bolder;
        /* width: 10rem; */
    }
}