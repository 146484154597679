.app-layout{
    .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover{
        background-color: #F0F5FF;
        color: #2F54EB;
    }

    .ant-menu-light .ant-menu-item-selected{
        background-color: #F0F5FF;
        color: #2F54EB;
    }
}