.Content{
    .TableInfo Button {
        margin: 10px 10px 10px 0;
    }
}

.companyBankAccount {
    .ant-table-wrapper .ant-table-pagination.ant-pagination {
        margin: 16px 0;
    }
}