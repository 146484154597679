.ant-carousel .slick-next::before {
    content: url("data:image/svg+xml;utf8, %3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22rgba(149%2C%20149%2C%20149%2C%201)%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M7.00015%200.585938L18.4144%2012.0002L7.00015%2023.4144L5.58594%2022.0002L15.5859%2012.0002L5.58594%202.00015L7.00015%200.585938Z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}

.ant-carousel .slick-prev::before {
    content: url('data:image/svg+xml;utf8, %3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22rgba(149%2C%20149%2C%20149%2C%201)%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M7.00015%200.585938L18.4144%2012.0002L7.00015%2023.4144L5.58594%2022.0002L15.5859%2012.0002L5.58594%202.00015L7.00015%200.585938Z%22%20transform%3D%22rotate(180%2012%2012)%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
}

.ant-carousel .slick-dots li button {
    position: relative;
    display: block;
    width: 100%;
    height: 3px;
    padding: 0;
    margin-top: 10px;
    color: transparent;
    font-size: 0;
    background: rgb(47, 87, 250);
    border: 0;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    opacity: 0.3;
    transition: all 0.3s;
}

