.registration {

  .SECTION-LEFT {
    width: 100%;
    height: 100%;
    text-align: center;
    margin: auto;
    margin-top: 8%;
  }

  .SECTION-RIGHT { 
    width: 100%; 
  }

  .infor-icon {
    color: rgb(24, 119, 242);
    font-size: 40px;
    margin-bottom: 24px;
    padding: 16px;
    background-color: rgb(232, 241, 254);
    border-radius: 100%;
  }
}

.registration .SECTION-LEFT .FormSignUp {
  margin: 0 7vw 0 7vw;
  /* max-width: 24vw;
  margin-left: 12vw;
  margin-top: 6vh; */
}

.success,
.fail,
.confirmation {
  display: flex;
  .SECTION-LEFT {
    width: 100%;
    height: 100%;
    text-align: center;
    margin: auto;
    /* margin-top: 8%; */
  }

  .SECTION-RIGHT { 
    width: 100%; 
  }

  .infor-icon {
    color: rgb(24, 119, 242);
    font-size: 40px;
    margin-bottom: 24px;
    padding: 16px;
    background-color: rgb(232, 241, 254);
    border-radius: 100%;
  }
}

.registration .SECTION-LEFT .FormSignUp {
  margin: 0 7vw 0 7vw;
  /* max-width: 24vw;
  margin-left: 12vw;
  margin-top: 6vh; */
}

.registration {
  .ant-form-item .ant-form-item-extra {
    clear: both;
    color: #1677ff;
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.5;
    text-align: start;
}
}

